import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
import { Helmet } from "react-helmet";
import LogoDark from "../assets/images/logo-dark.png";
import { useParams } from 'react-router-dom';
import './css/BlogPost.css';
import Footer from "./Footer";
import FloatingCookies from "./FloatingCookies";
import FloatingWhatsAppButton from "./FloatingWhatsAppButton";

export default function BlogPost() {
  const [isOpen, setMenu] = useState(true);
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = () => {
      fetch(`https://servidor-backend-oliveira-silva.vercel.app/api/blog`)
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            if (data.length > 0) {
              setBlog(data);
            } else {
              fetchData();
            }
          }
        })
        .catch(error => console.error('Erro ao buscar posts do blog:', error));
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, []);

  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title);
  const post = blog.find(item => item.url === decodedTitle);

  if (!post) return <div>Post não encontrado</div>;

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  const closeMenu = () => {
    setMenu(true);
  };

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.image} />
      </Helmet>

      <div>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <Link
              className="nav-item"
              activeclassname="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <span>
                <img src={LogoDark} className="inline-block dark:hidden" alt="" />
              </span>
            </Link>
            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                <li className="inline ms-1">
                  <Link2
                    id="email"
                    to="mailto:contato@advocaciaoliveiraesilva.adv.br"
                    className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"
                  >
                    <i className="uil uil-mailbox"></i>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <Link2
                    id="instagram"
                    target="_blank"
                    to="https://www.instagram.com/adv_oliveiraesilva"
                    className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"
                  >
                    <i className="uil uil-instagram"></i>
                  </Link2>
                </li>
              </ul>
              <button
                data-collapse="menu-collapse"
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
              className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
              style={{ marginLeft: 60 }}
            >
              <ul className="navbar-nav" id="navbar-navlist">
                <Link2
                  className="nav-item"
                  to="/"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  <span className="nav-link">Início</span>
                </Link2>
                <Link2
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="/"
                  onClick={closeMenu}
                >
                  <span className="nav-link">Nossa Historia</span>
                </Link2>
                <Link2
                  className="nav-item"
                  to="/"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  <span className="nav-link">Áreas de Atuação</span>
                </Link2>
                <Link2
                  className="nav-item"
                  to="/"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  <span className="nav-link">Time</span>
                </Link2>
                <Link2
                  className="nav-item"
                  to="/"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  <span className="nav-link">Publicações</span>
                </Link2>
                <Link2
                  className="nav-item"
                  to="/"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  <span className="nav-link">Vamos Conversar ?</span>
                </Link2>
              </ul>
            </div>
          </div>
        </nav>

        <section className="relative md:py-24 py-16" id="blog-post">
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <div style={{display: 'flex', marginTop: 15}}>
                  <Link2 to="/" className="text-blue-500 hover:text-blue-700">
                    &#8592; Voltar
                  </Link2>
                </div>
              <div className="flex items-center justify-between">
                <div>
                </div>
                <h2 className="title-post">{post.title}</h2>
                <div></div>
              </div>
            </div>
            <div className="principal">
              <div className="bloco">
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ borderRadius: 5, float: "left", marginRight: "10px" }}
                  className="mb-6"
                />
              </div>
              <p className="mb-4" dangerouslySetInnerHTML={{ __html: post.description }}></p>
            </div>
          </div>
        </section>

        {/* Footer*/}
        <Footer />
        {/* FloatingWhatsAppButton*/}
        <FloatingWhatsAppButton />
        {/* FloatingCookies*/}
        <FloatingCookies />
      </div>
    </>
  );
}
