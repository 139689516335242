import React, { useState } from "react";
import { Link } from "react-router-dom";
import './css/Privacy.css';

export default function Services() {

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const direitos = [
    {
      title: 'Direito Civil',
      icon: 'users-solid.svg',
      description: "",
      descriptionLarge: `
        <p class="paragraph-margin">Acreditamos que a excelência, organização e ética devem andar de mãos dadas com um atendimento compassivo e personalizado.</p>        
        <p class="paragraph-margin">Nossa missão é guiar você através do labirinto legal com clareza, integridade e a máxima atenção às suas necessidades individuais.</p>        
        <p class="paragraph-margin"><strong>1. Direito Cível</strong></p>
        <p class="paragraph-margin">a) <strong>Direito de Família:</strong> Em momentos que transformam a vida, como um divórcio, a sucessão ou a discussão sobre a guarda dos filhos, você merece mais do que orientação legal; merece ser atendido por uma equipe de advogados que sejam éticos, organizados, transparentes e, acima de tudo, empáticos, para concretizar seus direitos enquanto cuidam das suas emoções. Dedicamo-nos a encontrar soluções que harmonizem os interesses legais com o bem-estar da sua família.</p>
        <p class="paragraph-margin">b) <strong>Direito das Coisas:</strong> Seu patrimônio é o seu legado, e protegê-lo é nossa especialidade. Seja na definição de direitos de propriedade, na gestão de posse ou na negociação de direitos reais, nossa equipe aplica uma abordagem meticulosa para garantir que seus bens estejam seguros hoje e para as futuras gerações.</p>
        <p class="paragraph-margin">c) <strong>Direito das Obrigações:</strong> Entendemos que cada contrato, cada acordo e cada obrigação legal carregam consigo uma história, um objetivo, uma esperança. Nosso compromisso é assegurar que suas transações e relações jurídicas reflitam seus interesses de forma justa, com clareza nas obrigações e firmeza na proteção dos seus direitos.</p>
        <p class="paragraph-margin">d) <strong>Responsabilidade Civil:</strong> A Responsabilidade Civil é um conceito fundamental no universo jurídico, atuando como guardiã das relações interpessoais e comerciais. Ela se apresenta como a base para regular as ações e omissões que, infelizmente, acabam por prejudicar outrem, garantindo que haja uma reparação justa e adequada. Neste contexto, nosso escritório de advocacia se destaca por mediar essas questões com sensibilidade e precisão, atuando em caso de dano material ou moral.</p>
      `,
    },
    {
      title: 'Direito Criminal',
      icon: 'handcuffs-solid.svg',
      description: '',
      descriptionLarge: `
      <p class="paragraph-margin">No âmbito do Direito Penal, promovemos uma defesa intransigente de nossos clientes, garantindo a proteção de seus direitos fundamentais. Damos especial atenção à presunção de inocência, à liberdade de locomoção como regra e ao devido processo legal. Nossa equipe de advocacia se pauta pela ética, transparência, eficiência e organização na defesa dos direitos de nossos clientes. Atuamos principalmente nas seguintes fases da persecução penal, entre outras:</p>
      <p class="paragraph-margin"><strong>a) Prisões:</strong> Garantimos uma intervenção imediata em casos de prisões em razão da persecução penal, assegurando o cumprimento de todos os procedimentos legais e o respeito aos direitos fundamentais dos nossos clientes.</p>
      <p class="paragraph-margin"><strong>b) Investigações:</strong> Atuamos ativamente durante a investigação de infrações penais, acompanhando nossos clientes em todos os atos praticados no decorrer da investigação.</p>
      <p class="paragraph-margin"><strong>c) Acordos de Não Persecução Penal:</strong> Analisamos e representamos nossos clientes nos acordos de não persecução penal.</p>
      <p class="paragraph-margin"><strong>d) Medidas Cautelares:</strong> Intervimos e acompanhamos nossos clientes em situações envolvendo o cumprimento de medidas cautelares previstas no Código de Processo Penal.</p>
      <p class="paragraph-margin"><strong>e) Ação Penal:</strong> Defendemos nossos clientes desde o início até o término da ação penal. Desde a resposta à denúncia até as alegações finais e a eventual interposição de recursos, oferecemos uma defesa técnica detalhada e assertiva, comprometida com o devido processo legal e com o princípio da presunção de inocência.</p>
      <p class="paragraph-margin"><strong>e) Juizado Especial Criminal:</strong> Em crimes de menor potencial ofensivo, oferecemos auxílio indispensável para garantir os direitos de nossos clientes, respeitando o devido processo legal e o procedimento sumaríssimo, interpretados conforme a jurisprudência e os enunciados do FONAJE.</p>
      <p class="paragraph-margin"><strong>f) Habeas Corpus:</strong> Impetramos Habeas Corpus para enfrentar situações de prisão ou ameaças à liberdade de locomoção, visando corrigir ilegalidades e prevenir abusos.</p>
      <p class="paragraph-margin"><strong>g) Recursos:</strong> Interpomos recursos em todas as instâncias judiciais, incluindo TJ, TRF, STJ e STF, com o objetivo de reformar decisões judiciais desfavoráveis a nossos clientes.</p>
      <p class="paragraph-margin">Além de oferecer os serviços jurídicos anteriormente mencionados, nosso escritório também se dedica à defesa dos direitos das vítimas de crimes. Este aspecto do nosso trabalho é voltado para garantir que as vítimas recebam a devida assistência jurídica, apoio e representação durante o processo penal e em quaisquer medidas legais subsequentes.</p>
      <p class="paragraph-margin"><strong>a) Assistência Jurídica à Vítima:</strong> Oferecemos orientação jurídica completa às vítimas de crimes. Isso inclui suporte para a formalização de denúncias, registro de notícia de crime, acompanhamento em oitivas e a garantia de que seus direitos sejam respeitados pelo Estado.</p>
      <p class="paragraph-margin"><strong>b) Investigação:</strong> Acompanhamos as vítimas em todas as fases da persecução penal, assegurando que sua voz seja ouvida.</p>
      <p class="paragraph-margin"><strong>c) Assistência ao Ministério Público:</strong> Em todos os termos da ação penal pública, a vítima ou seu representante legal poderão ser admitidos na ação penal para intervir como assistente do Ministério Público. Nesse caso, oferecemos assistência às vítimas no curso da ação penal.</p>
      <p class="paragraph-margin"><strong>d) Medidas Protetivas de Urgência:</strong> Em casos de violência doméstica e outras situações de risco, atuamos na elaboração de requerimento de medidas protetivas de urgência, visando à proteção imediata da vítima contra o agressor.</p>
      <p class="paragraph-margin"><strong>e) Indenização por Danos Morais e Materiais:</strong> Orientamos e representamos vítimas na busca por reparação civil por danos morais e materiais decorrentes de crimes, trabalhando para que recebam a devida compensação pelas perdas e sofrimentos experimentados.</p>
      <p class="paragraph-margin"><strong>f) Acordos de Não Persecução Penal e de Suspensão Condicional do Processo:</strong> Nos casos aplicáveis, garantimos que os interesses das vítimas sejam considerados em acordos de não persecução penal ou na suspensão condicional do processo, visando a reparação dos danos e a aplicação de medidas que previnam que o crime ocorra novamente contra a vítima.</p>
      <p class="paragraph-margin">A defesa das vítimas de crimes é uma parte integral do nosso compromisso com a promoção da dignidade humana. Entendemos a importância de uma representação legal que não apenas busque a punição dos infratores, mas também assegure o respeito, a proteção e a reparação às vítimas, contribuindo para a sua recuperação e para a restauração de sua dignidade.</p>
      `,       
    },
    {
      title: 'Direito Sanitário',
      icon: 'staff-snake-solid.svg',
      description: '',
      descriptionLarge: `
      <p class="paragraph-margin">No complexo panorama das legislações que regem nossas sociedades, o Direito Sanitário ocupa uma posição de destaque, refletindo a crescente conscientização sobre a importância da saúde pública e individual. </p>
      <p class="paragraph-margin">Nosso escritório de advocacia oferece uma abordagem abrangente e humanizada neste campo, dedicando-se à proteção dos direitos relacionados à saúde e à garantia de um acesso justo e equitativo aos serviços de saúde, dentro de um quadro legal ético e responsável.</p> 
      <p class="paragraph-margin">A atuação de nosso escritório em Direito Sanitário abrange uma ampla variedade de serviços, projetados para atender tanto instituições de saúde quanto indivíduos, assegurando a conformidade com as normas sanitárias e a defesa dos direitos fundamentais à saúde.</p>
      <p class="paragraph-margin"><strong>a) Regulação e Compliance Sanitário:</strong> Oferecemos consultoria a instituições de saúde, incluindo hospitais, clínicas e laboratórios, para garantir a conformidade com as regulamentações sanitárias vigentes. Isso envolve a adequação a normas técnicas, licenciamento sanitário, boas práticas de fabricação e distribuição de produtos de saúde, e a implementação de políticas de controle de infecções.</p>
      <p class="paragraph-margin"><strong>b) Direitos dos Pacientes e Ética Médica:</strong> Prestamos assessoria sobre os direitos dos pacientes, incluindo o consentimento informado, privacidade e confidencialidade das informações de saúde, bem como em questões éticas relacionadas à prática médica e ao atendimento em saúde.</p>
      <p class="paragraph-margin"><strong>c) Vigilância Sanitária e Segurança de Produtos:</strong> Assessoramos empresas do setor de alimentos, cosméticos, medicamentos e dispositivos médicos na navegação pelas regulamentações de vigilância sanitária, contribuindo para a segurança e a eficácia dos produtos oferecidos ao consumidor.</p>
      <p class="paragraph-margin"><strong>d) Litígios e Defesa em Ações Sanitárias:</strong> Representamos clientes em processos administrativos e judiciais relacionados a questões de Direito Sanitário, incluindo defesa em casos de infrações sanitárias, disputas sobre registro e comercialização de produtos de saúde, e ações civis públicas por danos causados à saúde pública.</p>
      <p class="paragraph-margin"><strong>e) Políticas Públicas de Saúde:</strong> Trabalhamos junto a entidades e organizações na formulação, implementação e avaliação de políticas públicas de saúde, visando promover melhorias no sistema de saúde e a proteção da saúde da população.</p>
      <p class="paragraph-margin">Nosso compromisso com o Direito Sanitário reflete a compreensão da saúde como um direito fundamental, intrinsecamente ligado à dignidade da pessoa humana. Nossa equipe de advogados especializados está preparada para oferecer soluções jurídicas inovadoras e efetivas, contribuindo para a promoção da saúde pública e a proteção dos direitos dos indivíduos no âmbito da saúde.</p>
      `,
    },
    {
      title: 'Direito Médico',
      icon: 'stethoscope-solid.svg',
      description: '',
      descriptionLarge: `
      <p class="paragraph-margin"><strong>2. Direito Médico</strong></p>
      <p class="paragraph-margin">Em um mundo onde a saúde e os direitos se entrelaçam de maneira complexa e delicada, nosso escritório de advocacia se destaca pela profunda especialização em Direito Médico. Compreendemos as nuances e os desafios que permeiam a interseção entre a medicina e o direito, e é por isso que nos dedicamos a oferecer serviços de consultoria, assessoria e representação legal e extrajudicial com excelência e humanidade.</p>
      <p class="paragraph-margin">Nossa atuação abrange desde a orientação preventiva, destinada a médicos, profissionais da saúde e instituições médicas, até a resolução de conflitos de natureza legal. Acreditamos na importância de uma consultoria jurídica sólida que possa antecipar e mitigar riscos, garantindo a segurança jurídica necessária para o exercício da medicina com tranquilidade e confiança.</p>
      <p class="paragraph-margin">Por meio de nossa assessoria, acompanhamos de perto os desafios enfrentados por nossos clientes, oferecendo soluções personalizadas que respeitam a especificidade de cada caso. Seja na elaboração de contratos, na orientação sobre normas éticas e legais, ou na condução de processos administrativos, nossa equipe está preparada para assistir nossos clientes com a máxima competência e dedicação.</p>
      <p class="paragraph-margin">Além disso, destacamos nossa capacidade em atuar no ajuizamento de ações para a obtenção de medicamentos, exames, cirurgias, e outros procedimentos médicos essenciais, que muitas vezes são negados aos pacientes. Essa frente de atuação é vital, pois assegura o acesso à saúde e a tratamentos adequados, refletindo nosso compromisso não apenas com a legalidade, mas com a vida e o bem-estar dos indivíduos.</p>
      <p class="paragraph-margin">Nossa experiência em representação legal e extrajudicial também nos permite defender os interesses de nossos clientes com assertividade, seja em negociações, mediações ou em procedimentos administrativos perante conselhos de classe e outras entidades reguladoras. Entendemos que muitas questões podem ser resolvidas de maneira eficaz fora dos tribunais, economizando tempo e recursos para todos os envolvidos.</p>
      <p class="paragraph-margin">Nossa abordagem é pautada pelo diálogo, pela transparência e pelo compromisso ético. Buscamos entender profundamente as preocupações e objetivos de cada cliente para oferecer um acompanhamento jurídico que não apenas resolva conflitos, mas também promova práticas mais seguras e éticas no campo da saúde.</p>
      <p class="paragraph-margin">No coração de nossa atuação em Direito Médico está o compromisso com a defesa dos direitos, a promoção da dignidade humana e a contribuição para um ambiente médico mais seguro e justo.</p>`
    },
    {
      title: 'Direito do Consumidor',
      icon: 'cart-shopping-solid.svg',
      description: '',
      descriptionLarge: `
      <p class="paragraph-margin"><strong>3. Direito Consumerista</strong></p>
      <p class="paragraph-margin">No cenário atual, onde as relações de consumo tornam-se cada vez mais complexas e sujeitas a constantes mudanças legislativas, nosso escritório se destaca pela prestação de serviços jurídicos especializados em Direito do Consumidor. Com um olhar humanizado e uma abordagem centrada nas necessidades específicas de cada cliente, oferecemos um amplo espectro de serviços que abrangem consultoria, assessoria e representação legal e extrajudicial.</p>
      <p class="paragraph-margin">Nossa atuação em consultoria visa orientar preventivamente nossos clientes, sejam eles consumidores ou fornecedores, a respeito de seus direitos e deveres nas relações de consumo. Isso inclui a elaboração e revisão de contratos, políticas de troca e devolução, bem como a adequação de práticas comerciais às normativas vigentes, visando evitar litígios e garantir uma relação harmônica entre as partes.</p>
      <p class="paragraph-margin">Na esfera da assessoria, nosso trabalho se intensifica no acompanhamento contínuo das atividades de nossos clientes, provendo soluções jurídicas personalizadas para questões emergentes e orientando sobre as melhores práticas no âmbito do Direito do Consumidor. Isso engloba desde o esclarecimento de dúvidas rotineiras até o desenvolvimento de estratégias para o manejo de crises ou conflitos consumeristas, sempre com o objetivo de proteger os interesses de nossos clientes e minimizar riscos legais.</p>
      <p class="paragraph-margin">Quanto à representação legal e extrajudicial, estamos preparados para defender os direitos de nossos clientes em todas as instâncias. Isso inclui a negociação e resolução de conflitos de consumo fora dos tribunais, prática que, além de mais ágil, pode resultar em soluções mais satisfatórias para todas as partes envolvidas. Quando o litígio se faz inevitável, nossa equipe de advogados especializados em Direito do Consumidor está pronta para atuar de forma assertiva na representação de nossos clientes perante o Poder Judiciário, buscando sempre os melhores resultados.</p>
      <p class="paragraph-margin">Nossa expertise abrange diversas áreas dentro do Direito do Consumidor, incluindo, mas não se limitando a, questões relacionadas a produtos defeituosos, práticas abusivas, publicidade enganosa, problemas em serviços de telecomunicações, direitos básicos do consumidor, contratos de adesão, e muito mais. Com uma abordagem integral e multidisciplinar, buscamos oferecer soluções jurídicas eficientes e inovadoras, alinhadas às mais recentes tendências do direito e às necessidades de nossos clientes.</p>
      <p class="paragraph-margin">Ao escolher nosso escritório, você garante não apenas a excelência técnica e a vasta experiência de nossos profissionais, mas também um compromisso inabalável com a defesa dos seus direitos enquanto consumidor ou fornecedor.</p>
      <p class="paragraph-margin">Estamos aqui para assegurar que suas relações de consumo sejam sempre justas, equilibradas e plenamente alinhadas à legislação consumerista.</p>
      `,
    },
    {
      title: 'Direito do Trabalho',
      icon: 'helmet-safety-solid.svg',
      description: '',
      descriptionLarge: '',
    },
    {
      title: 'Direito Digital',
      icon: 'direito-digital1.png',
      description: '',
      descriptionLarge: `
      <p class="paragraph-margin"><strong>4. Direito Digital</strong></p>
      <p class="paragraph-margin">Em um mundo cada vez mais dominado pela tecnologia e pela digitalização das relações sociais e comerciais, o Direito Digital emerge como um campo essencial para garantir que a inovação caminhe lado a lado com a segurança jurídica. Nosso escritório de advocacia reconhece a importância de estar à frente nas questões legais que envolvem o ambiente digital, oferecendo uma abordagem humanizada e altamente especializada para atender às necessidades de nossos clientes nesse contexto.</p>
      <p class="paragraph-margin">Nossa equipe está preparada para oferecer consultoria e assessoria em uma ampla gama de questões relativas ao Direito Digital, orientando nossos clientes sobre como navegar com segurança e conformidade através dos desafios e oportunidades apresentados pela internet e pelas novas tecnologias. Isso inclui:</p>
      <p class="paragraph-margin"><strong>a) Proteção de Dados e Privacidade:</strong> Assessoramos empresas e indivíduos sobre como se adequar às normativas de proteção de dados, como a LGPD no Brasil e o GDPR na Europa, incluindo a implementação de políticas de privacidade e segurança da informação.</p>
      <p class="paragraph-margin"><strong>b) Compliance e Governança Digital:</strong> Orientamos sobre a conformidade com as leis e regulamentos aplicáveis ao ambiente digital, ajudando na implementação de práticas de governança que promovam a ética e a transparência nas atividades online.</p>
      <p class="paragraph-margin"><strong>c) Propriedade Intelectual na Era Digital:</strong> Oferecemos consultoria sobre a proteção de direitos autorais, marcas e patentes no ambiente digital, bem como a negociação de licenças e a gestão de ativos digitais.</p>
      <p class="paragraph-margin"><strong>d) Contratos Digitais e E-commerce:</strong> Ajudamos na elaboração e revisão de contratos relacionados ao comércio eletrônico, licenciamento de software, desenvolvimento de websites e outras transações comerciais realizadas online.</p>
      <p class="paragraph-margin"><strong>e) Cybersegurança:</strong> Prestamos assessoria sobre as melhores práticas de segurança cibernética, ajudando a prevenir e responder a incidentes de segurança digital, protegendo assim as operações e a reputação de nossos clientes.</p>
      <p class="paragraph-margin">Além da consultoria e assessoria, nosso escritório está apto a representar clientes em litígios relacionados ao Direito Digital, tanto em âmbito judicial quanto extrajudicial. Isso inclui disputas sobre violações de direitos autorais digitais, crimes cibernéticos, conflitos relacionados a contratos digitais e questões de difamação online. Nossa abordagem busca resolver tais disputas de maneira eficaz, protegendo os interesses de nossos clientes enquanto navegamos pelas complexidades do ambiente digital.</p>
      <p class="paragraph-margin">Entendemos a importância de uma assessoria jurídica atualizada e alinhada às rápidas mudanças tecnológicas, garantindo que nossos clientes possam explorar as oportunidades do digital com confiança e proteção legal.</p>
      `,
    }
  ]

  const openModal = (service) => {
    setSelectedService(service);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const style1 = {marginTop: 22};

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Áreas de Atuação</h6>
              
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-2 gap-7 justify-center">
            {direitos.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <div style={{ width: '40px', height: '40px' }}>
                    <img src={'../assets/images/icons/' + item.icon} alt="Ícone" />
                  </div>
                </div>

                <div className="content mt-7">
                  <Link to='#' className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-gray-400 text-justify text-sm">{item.description}</p>

                </div>
                <button onClick={() => openModal(item)} className="text-sm text-black-600 mt-2 flex items-center">
                  Saiba Mais
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1.447.895l-5-2a1 1 0 0 1 0-1.79l5-2A1 1 0 0 1 10 3z"/>
                    <path fillRule="evenodd" d="M14 10a1 1 0 0 0-1-1H7.707l.646-.646a.5.5 0 0 0-.708-.708l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708l-.646-.646H13a1 1 0 0 0 1-1z"/>
                  </svg>
                </button>
              </div>
            ))}
          </div>
          <h3 style={{marginTop: 100, marginBottom: 50}} className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Compromisso com a proteção e concretização dos seus direitos.</h3>
          <p className="text-slate-400" style={style1}>Em nossa atuação diária como advogados especializados em Sete Lagoas, estamos sempre envolvidos na identificação de riscos, compreensão das responsabilidades e análise das consequências legais em diversas áreas. Adotamos uma abordagem humanizada, considerando as necessidades individuais dos nossos clientes.</p>
          <p className="text-slate-400" style={style1}>Entendemos a importância de um atendimento personalizado, especialmente em situações que envolvem aspectos complexos do direito que afetam a tranquilidade e bem-estar dos nossos clientes. Atuamos em campos como Direito Civil, Direito Criminal, Direito Médico, Direito Sanitário, Direito do Trabalho, Direito do Consumidor e Direito Digital, sempre atentos às tendências jurisprudenciais, doutrinárias e às constantes mudanças legislativas.</p>
          <p className="text-slate-400" style={style1}>Nosso escritório, com uma sólida trajetória em Sete Lagoas, está totalmente preparado para enfrentar as complexas demandas jurídicas da atualidade. Contamos com uma equipe de advogados experientes, cuja expertise é constantemente aprimorada através de especializações e participação em eventos jurídicos relevantes. Esta preparação contínua nos permite abordar cada caso com a profundidade e especialização necessárias, oferecendo consultoria e assessoria jurídica em Sete Lagoas de alto nível. Além de prestar serviços jurídicos em Sete Lagoas, nossa atuação se estende a outras regiões de Minas Gerais.</p>
          <p className="text-slate-400" style={style1}>Nosso escritório cresceu ao longo dos anos, sempre pautado por valores como empatia, ética, planejamento, organização e profissionalismo. Um dos nossos objetivos é assegurar a concretização dos direitos dos nossos clientes, protegendo seus interesses e proporcionando tranquilidade em todas as questões judiciais e extrajudiciais que enfrentarem. Estamos comprometidos em oferecer uma representação legal que reflete não apenas a excelência técnica, mas também um profundo entendimento das questões humanas envolvidas. Além disso, valorizamos imensamente o sigilo entre advogado e cliente, um pilar fundamental do nosso trabalho, especialmente ao fornecer consultoria e assessoria jurídica em Sete Lagoas. Nosso atendimento é pautado por um compartilhamento estratégico de informações com o cliente e por uma atuação responsável, ética, transparente e profissional.</p>
          <p className="text-slate-400" style={style1}>Em nossa jornada, tivemos a oportunidade de trabalhar em casos que reforçaram nossa visão sobre a importância do direito para a sociedade. Cada história de sucesso nos inspira a continuar evoluindo e a buscar sempre as melhores soluções para nossos clientes. Conte conosco para proteger seus interesses e assegurar a melhor representação legal possível em todas as suas necessidades jurídicas. Seja enfrentando desafios legais ou buscando assegurar seus direitos, estamos aqui para oferecer um apoio jurídico de confiança, profissional e, acima de tudo, humanizado. Contate-nos em Sete Lagoas para saber mais sobre como podemos ajudá-lo.</p>                   
        </div>

        {/* Modal */}
        {modalOpen && selectedService && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg relative max-w-md mx-auto" style={{ marginTop: "5pc", maxHeight: '82vh'}}>
              <button onClick={closeModal} className="absolute top-4 right-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="flex items-center mb-4">
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm mr-4">
                  <img src={'../assets/images/icons/' + selectedService.icon} alt="Ícone" className="w-12 h-12" />
                </div>
                <h2 className="text-lg font-bold">{selectedService.title}</h2>
              </div>
              <div style={{ maxHeight: "calc(80vh - 120px)", overflowY: "auto", marginBottom: 50 }}>
                <p className="text-gray-700 mt-4" dangerouslySetInnerHTML={{ __html: selectedService.descriptionLarge }} />
              </div>
            </div>
          </div>
        )}




      </section>
     
    </>
  );

}
