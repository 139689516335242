import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroudImage from "../../assets/images/bg/auth.jpg";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const response = await fetch(`https://servidor-backend-oliveira-silva.vercel.app/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || 'Erro ao fazer login');
      }

      const data = await response.json();
      localStorage.setItem('authToken', data.token);
      navigate('/auth/admin');
    } catch (error) {
      setError(error.message);
    }
  };

  
  
  

  return (
    <>
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="position-relative bg-center bg-cover"
      >
        <div className="absolute inset-0 bg-black opacity-90"></div>
        <div className="container-fluid relative">
          <div className="grid grid-cols-1">
            <div className="lg:col-span-4">
              <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                <div className=" my-auto">
                  <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                    <div className="grid grid-cols-1">
                      <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">
                        Login
                      </h5>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleLogin();
                        }}
                        className="ltr:text-left rtl:text-right"
                      >
                        {error && (
                            <div className="text-center text-red-500 mb-4">{error}</div>
                        )}
                        <div className="grid grid-cols-1">
                          <div className="mb-4">
                            <label
                              className="dark:text-white"
                              htmlFor="LoginEmail"
                            >
                              Email Address:
                            </label>
                            <input
                              id="LoginEmail"
                              type="email"
                              className="form-input mt-3"
                              placeholder="name@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="mb-4">
                            <label
                              className="dark:text-white"
                              htmlFor="LoginPassword"
                            >
                              Password:
                            </label>
                            <input
                              id="LoginPassword"
                              type="password"
                              className="form-input mt-3"
                              placeholder="Password:"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <div className="flex justify-between mb-4">
                            <div className="inline-flex items-center"></div>
                          </div>

                          <div className="mb-4">
                            <button
                              type="submit"
                              className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full"
                            >
                              Login / Sign in
                            </button>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
