import React, { useState, useEffect } from 'react';

const TypingEffect = ({ words }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typingDelay = 300; // Tempo de digitação de cada letra
    const erasingDelay = 200; // Tempo de apagar cada letra
    const newTextDelay = 10000; // Tempo de espera entre cada palavra
    let timerId;

    const handleTyping = () => {
      const word = words[currentWordIndex];
      if (isDeleting) {
        setCurrentWord(word.substring(0, currentWord.length - 1));
      } else {
        setCurrentWord(word.substring(0, currentWord.length + 1));
      }

      let delay = isDeleting ? erasingDelay : typingDelay;

      if (!isDeleting && currentWord === word) {
        delay = newTextDelay;
        setIsDeleting(true);
      } else if (isDeleting && currentWord === '') {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }

      timerId = setTimeout(handleTyping, delay);
    };

    timerId = setTimeout(handleTyping, typingDelay);
    return () => clearTimeout(timerId);
  }, [currentWord, currentWordIndex, isDeleting, words]);

  return (
    <>
      <span style={{ color: '#FF9800' }}>
        {currentWord}.
      </span>
    </>
  );
};

export default TypingEffect;
