import React from "react";
import { Link } from 'react-router-dom';

export default function Contact() {
    const handleMapClick = () => {
        // Replace the URL with the desired location and origin coordinates.
        const destination = 'R.+Gabi%C3%BAba,+221+-+Em%C3%ADlia,+Sete+Lagoas+-+MG,+35701-368/@-19.446139,-44.2918828,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa7dae24f499998e5:0x1cac73f32ea3363a!2m2!1d-44.2218423!2d-19.4461524?entry=ttu';
        const origin = 'Current+Location'; // You can use the user's current location if available.

        // Redirect the user to Google Maps with the route calculation.
        window.location.href = `https://www.google.com/maps/dir/${origin}/${destination}`;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Obtém os valores do formulário
      const name = e.target.name.value;
      const tel = e.target.tel.value;
      const email = e.target.email.value;
      const message = e.target.message.value;
  
      // Constrói a mensagem com os dados do formulário
      const whatsappMessage = `Olá! Me chamo ${name}. Meu telefone é ${tel}. Meu email é ${email}. A mensagem que gostaria de enviar é: ${message}`;
  
      // Codifica a mensagem para a URL
      const encodedMessage = encodeURIComponent(whatsappMessage);
  
      // Número de telefone para o qual você deseja enviar a mensagem (com o prefixo internacional)
      const phoneNumber = "5531998626959";
  
      // URL do WhatsApp Web com o número de telefone e a mensagem codificada
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  
      // Redireciona para o WhatsApp Web
      window.location.href = whatsappURL;
    };
    
  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
        <div className="container">
          <div className="grid grid-cols-1 pb-8">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Vamos Conversar ?</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Contato</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-1">
            <div className="border border-gray-300 my-4 py-4 px-2 rounded-md p-7">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">Nome</label>
                  <input type="text" name="name" id="name" autoComplete="name" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="tel" className="block text-sm font-medium text-gray-700 dark:text-white">Telefone</label>
                  <input type="tel" name="tel" id="tel" autoComplete="tel" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-white">Email</label>
                  <input type="email" name="email" id="email" autoComplete="email" className="mt-1 h-10 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"/>
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-white">Mensagem</label>
                  <textarea id="message" name="message" rows="4" className="mt-1 p-3 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-md"></textarea>
                </div>
                <div className="flex justify-end">
                  <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">Enviar</button>
                </div>
              </form>
            </div>
          </div>

            {/* Contact Information */}
            <div className="md:col-span-1 lg:ms-8">
              <div className="flex">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">WhatsApp</h5>
                  <a href="https://wa.me/5531998626959" className="text-slate-400">(31) 9.9862-6959</a>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                  <a href="mailto:contato@advocaciaoliveiraesilva.adv.br" className="text-slate-400">contato@advocaciaoliveiraesilva.adv.br</a>
                </div>
              </div>
              <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                      <i className="uil uil-clock block rounded text-2xl dark:text-white mb-0"></i>
                  </div>
                  <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">Horário</h5>
                      <p className="text-slate-400 mb-0">Segunda a Sexta: 09:00 - 18:00</p>
                  </div>
              </div>

              <Link target="_blank" onClick={handleMapClick}>
              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                </div>
                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">Localização</h5>
                  <p className="text-slate-400 mb-2">R. Gabiúba, 221 - Emília, Sete Lagoas - MG, 35701-368</p>
                </div>
              </div>
              </Link>

              <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                      <i className="uil uil-share block rounded text-2xl dark:text-white mb-0"></i>
                  </div>
                  <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">Redes Sociais</h5>
                      <div style={{ display: 'flex'}}>
                        <a href="https://www.instagram.com/adv_oliveiraesilva" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-instagram block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61553413335399" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-facebook block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5531998626959" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-whatsapp block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="mailto:contato@advocaciaoliveiraesilva.adv.br" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                        <a href="tel:+5531998626959" target="_blank" rel="noopener noreferrer">
                          <i className="uil mr-2 uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                        </a>
                      </div>
                  </div>
              </div>             
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
