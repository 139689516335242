import React, { useState } from 'react';
import { FaStar, FaNewspaper } from 'react-icons/fa';

const SidebarLeft = ({ handleItemSelected }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (itemId) => {
    setSelectedItem(itemId);
    handleItemSelected(itemId);
  };

  return (
    <nav className="sidebar-left">
      <ul className="text-black icones">
        <li className={`p-4 flex items-center liselecion ${selectedItem === 'avaliacoes' ? 'selected' : ''}`} onClick={() => handleItemClick('avaliacoes')}>
          <FaStar className="mr-2" />
          Avaliações
        </li>
        <li className={`p-4 flex items-center liselecion ${selectedItem === 'publicacoes' ? 'selected' : ''}`} onClick={() => handleItemClick('publicacoes')}>
          <FaNewspaper className="mr-2" />
          Publicações
        </li>
      </ul>
    </nav>
  );
};

export default SidebarLeft;
