import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexFour from "./pages/index.js";
import Login from "./pages/auth/login.js";
import PageAdmin from "./pages/auth/admin.js";
import BlogPost from "./component/BlogPost.js";

import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';
import './assets/scss/icons.scss';

export default function App() {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<IndexFour/>} />
        <Route path="/inicio" element={<IndexFour/>} />
        <Route path="/contato" element={<IndexFour/>} />
        <Route path="/blog" element={<IndexFour/>} />
        <Route path="/blog/:title" element={<BlogPost />} />
        <Route path="/auth" element={<Login />} />
        <Route path="/auth/admin" element={<PageAdmin/>} />
      </Routes>
    </>
  );
}
