import React from "react";
import { Link } from "react-scroll";


export default function Footer() {

    return (
        <>
            {/*  Start Footer  */}
            <footer className="py-8 bg-slate-800 dark:bg-gray-900">
                <div className="container">
                    <div className="grid items-center">

                        <div className="md:col-span-5 md:mt-0 mt-8">
                            <div className="text-center">
                                <a href="https://www.advocaciaoliveiraesilva.adv.br/term-privacy/termo-de-privacidade-oliveira-e-silva.pdf" style={{color: 'white'}}>Termo de Responsabilidade</a>
                                <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Advocacia Oliveira e Silva. Todos os direitos reservados <i className="mdi mdi-heart text-orange-700"></i> Desenvolvido  por <a style={{color: '#c58e29'}} href="https://keepo.io/m2u/" className="text-reset">M2U</a>.</p>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </footer>
            {/* <!-- End Footer --> */}

            {/* <!-- Back to top --> */}
            <Link to="home" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-orange-600 text-white leading-9"><i className="uil uil-arrow-up"></i></Link>
            {/* <!-- Back to top --> */}
        </>
    );

}

