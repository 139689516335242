import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoDark from "../assets/images/logo-dark.png";

import "../../node_modules/react-modal-video/scss/modal-video.scss";

import Services from "../component/Services";
import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import FloatingWhatsAppButton from "../component/FloatingWhatsAppButton";
import FloatingCookies from "../component/FloatingCookies";

import TypingEffect from './common/TypingEffect';
import Team from "../component/Team";
import Blog from "../component/Blog";


export default function IndexFour() {
    const [isOpen, setMenu] = useState(true);
    const words = ['ética', 'planejamento', 'responsabilidade'];
    window.addEventListener("scroll", windowScroll);

    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    const closeMenu = () => {
        setMenu(true);
    };

    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("is-sticky");
        } else {
            navbar.classList.remove("is-sticky");
        }
    }


    return (
        <>
            <div>
                <nav className="navbar bg-white" id="navbar">
                    <div className="container flex flex-wrap items-center justify-end">
                            <Link
                                    className="nav-item"
                                    to="home"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                <span>
                                    <img src={LogoDark} className="inline-block dark:hidden" alt="" />
                                </span>
                            </Link>
                        <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                            <ul className="list-none menu-social mb-0">
                                <li className="inline ms-1">
                                    <Link2 id="email" to="mailto:contato@advocaciaoliveiraesilva.adv.br" className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                                        <i className="uil uil-mailbox"></i>
                                    </Link2>
                                </li>
                                <li className="inline ms-1">
                                    <Link2 id="instagram" target="_blank" to="https://www.instagram.com/adv_oliveiraesilva" className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                                        <i className="uil uil-instagram"></i>
                                    </Link2>
                                </li>
                            </ul>
                            <button
                                data-collapse="menu-collapse"
                                type="button"
                                className="collapse-btn inline-flex items-center ms-3 text-light light:text-white lg_992:hidden"
                                onClick={toggleMenu}
                            >
                                <span className="sr-only">Menu</span>
                                <i className="mdi mdi-menu mdi-24px"></i>
                            </button>
                        </div>

                        <div
                            className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
                            id="menu-collapse"
                            style={{marginLeft: 60}}
                        >
                            <ul className="navbar-nav" id="navbar-navlist">
                                <Link
                                    className="nav-item"
                                    to="home"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Início</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    to="about"
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Nossa Historia</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="features"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Áreas de Atuação</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="team"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Time</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="blog"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Publicações</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="contact"
                                    activeclassname="active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    onClick={closeMenu}
                                >
                                    <span className="nav-link">Vamos Conversar ?</span>
                                </Link>
                                
                                
                            </ul>
                        </div>
                    </div>
                </nav>
                <section className="relative table w-full py-36 pb-0 lg:py-44 lg:pb-0 bg-gradient-to-b from-yellow-600/40 via-white-600/20 to-white-50 bg-slate-800 mb-8" id="home">
                    <div style={{ paddingBottom: 100 }} className="container">
                        <div style={{ marginTop: 100 }} className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                            <div className="lg:col-span-7">

                                <div className="mt-14 md:me-6 md:mb-20">
                                    <h4 className="lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative text-white">Protegendo seus direitos <br /> com <TypingEffect words={words}/></h4>
                                    <p className="text-slate-400 text-white/70 mb-0 max-w-2xl text-lg">Atuando há mais de 6 anos em Sete Lagoas, Minas Gerais, protegendo e garantindo seus direitos.</p>

                                    <div style={{ paddingBottom: 35 }} className="relative mt-10">
                                        <Link
                                            className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full me-1"
                                            to="contact"
                                            activeclassname="active"
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                        >Contato</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="relative">
                    <div className="shape overflow-hidden text-gray-50 dark:text-slate-800">
                        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

            </div>
            {/* About section */}
            <About />
            {/* Service section */}
            <Services /> 
            {/* Team section */}
            <Team />
            {/* BLOG section */}
            <Blog />
            {/* Contact section */}
            <Contact />
            {/* Footer section */}
            <Footer />
            {/* FloatingWhatsAppButton*/}
            <FloatingWhatsAppButton/>
            {/* FloatingCookies*/}
            <FloatingCookies/>
            {/* FloatingCookies*/}
        </>
    );

}
